import Container from "@material-ui/core/Container"
import Paper, { PaperProps } from "@material-ui/core/Paper"
import React from "react"
import styled from "styled-components"

export type StyledFormProps = PaperProps & {
  isLoading: boolean
}

type FormWrapperProps = {
  width?: number
}
const defaultWidth = 580
export const FormWrapper = styled(({ width, ...props }) => (
  <Container {...props} />
))<FormWrapperProps>`
  margin: 0;
  @media (min-width: ${({ width }) => (width ? width : defaultWidth)}px) {
    max-width: ${({ width }) => (width ? width : defaultWidth)}px;
  }
`

export const StyledForm = styled(({ isLoading, ...props }) => (
  <Paper {...props} component="form" elevation={3} />
))<StyledFormProps>`
  cursor: ${({ isLoading }) => (isLoading ? "wait" : "default")};
  padding: ${({ theme }) => `${theme.spacing(4)}px`};

  > * + * {
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
  }

  .noshow {
    display: none !important;
  }
`
