import MuiContainer from "@material-ui/core/Container"
import React from "react"
import styled from "styled-components"
import {
  PageBuilderActiviteitFormFragment,
  PageBuilderErvaringFormFragment,
  PageBuilderFormFragment,
  PageBuilderNieuwsFormFragment,
  PageBuilderVacatureFormFragment,
} from "../../../__generated__/graphql-gatsby"
import WpContent from "../wpContent"
import Form from "./form"
import Newsletter from "./newsletter"

interface IProps {
  block:
    | PageBuilderFormFragment
    | PageBuilderErvaringFormFragment
    | PageBuilderActiviteitFormFragment
    | PageBuilderNieuwsFormFragment
    | PageBuilderVacatureFormFragment
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`

const TextWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(2.5)}px;
  margin-left: auto;
  max-width: 50%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 720px;
  }
`

const Container = styled(MuiContainer)`
  margin-top: ${({ theme }) => theme.spacing(10)}px;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`

const FormBlock: React.FC<IProps> = ({ block }) => (
  <Container>
    <Wrapper>
      {block.addtext && (
        <TextWrapper>
          <WpContent dangerouslySetInnerHTML={{ __html: String(block.text) }} />
        </TextWrapper>
      )}
      {block.nieuwsbriefFormulier ? (
        <Newsletter />
      ) : (
        <Form
          block={block.form}
          width={block.addtext ? undefined : 828}
          redirectUrl={block.redirect?.url}
        />
      )}
    </Wrapper>
  </Container>
)

export default FormBlock
