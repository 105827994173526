import { graphql, useStaticQuery } from "gatsby"
import { GraphQLClient } from "graphql-request"
import { wpUrlQueryQuery } from "../__generated__/graphql-gatsby"
import { getSdk } from "../__generated__/graphql-wp"

const useGqlClient = () => {
  const data = useStaticQuery<wpUrlQueryQuery>(graphql`
    query wpUrlQuery {
      wp {
        generalSettings {
          url
        }
      }
    }
  `)

  const { url } = { ...data.wp?.generalSettings }

  if (url) {
    const client = new GraphQLClient(`${url}/graphql`)
    const sdk = getSdk(client)

    return sdk
  } else {
    throw new Error("No backend graphql endpoint found in Gatsby")
  }
}

export default useGqlClient
