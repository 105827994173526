import { useEffect, useState } from "react"

const useClientIp = () => {
  const [ip, setIp] = useState<string | undefined>()

  useEffect(() => {
    const getIp = async () => {
      try {
        const res = await fetch(`https://geolocation-db.com/json/`)
        const data = await res.json()
        setIp(data.IPv4)
      } catch (e) {
        console.error(e)
      }
    }

    getIp()
  }, [])

  return ip
}

export default useClientIp
