import { graphql, useStaticQuery } from "gatsby"
import { NewsletterListsQuery } from "../__generated__/graphql-gatsby"

const useNewsletterListId = (listName = "Gasten") => {
  const query = useStaticQuery<NewsletterListsQuery>(graphql`
    query NewsletterLists {
      wp {
        availableNewslettersLists {
          name
          list_id
        }
      }
    }
  `)

  const list = query?.wp?.availableNewslettersLists?.find(
    list => list?.name === listName
  )

  return list?.list_id
}

export default useNewsletterListId
