import React from "react"

interface IProps {
  height: Maybe<number>
}

const Spacer: React.FC<IProps> = ({ height }) => (
  <div style={{ width: "100%", minHeight: height || 16 }} />
)

export default Spacer
