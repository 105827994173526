import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import MuiLink from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import { Link } from "gatsby"
import React, { FormEvent, useState } from "react"
import styled from "styled-components"
import useClientIp from "../../../hooks/useClientIp"
import useGqlClient from "../../../hooks/useGqlClient"
import useNewsletterListId from "../../../hooks/useNewsletterListId"

enum FormState {
  INIT = "INIT",
  LOADING = "LOADING",
  FAILED = "FAILED",
  SUCCES = "SUCCES",
}

type Update = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => void

const Wrapper = styled(props => <Paper elevation={3} {...props} />)`
  position: relative;
  padding: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3.5)}px;
  width: 100%;
  max-width: 500px;
  height: 295px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: ${({ theme }) => theme.spacing(2)}px;
    padding-bottom: ${({ theme }) => theme.spacing(1.5)}px;
    height: 332px;
    max-width: 452px;
  }

  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`

const Header = styled(props => <Typography {...props} />)<TypographyProps<"p">>`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const NameWrapper = styled.div`
  width: 100%;
  > * + * {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    > * + * {
      margin-left: 0;
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`

const Spinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const BtnWrapper = styled.div<{
  position: "flex-start" | "flex-end" | "center"
}>`
  display: flex;
  justify-content: ${({ position }) => position};
  align-items: center;
`

const BaseInput = styled(props => (
  <TextField required color="secondary" variant="outlined" {...props} />
))`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`

const BaseText: React.FC<TypographyProps> = props => (
  <Typography variant="body2" {...props} />
)

const Newsletter: React.FC = () => {
  const client = useGqlClient()
  const ipAdress = useClientIp()
  const listId = useNewsletterListId()
  const [formState, setFormState] = useState<FormState>(FormState.INIT)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  })

  const update: Update = e =>
    setFormData(s => ({ ...s, [e.target.id]: e.target.value }))

  const reset = () => setFormState(FormState.INIT)

  const submit = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormState(FormState.LOADING)

    if (ipAdress) {
      const { email, firstName, lastName } = formData

      const res = await client.SignupNewsletter({
        input: {
          email,
          firstName,
          lastName,
          ipAdress,
          listId,
        },
      })

      if (res.registerForNewsletter?.succes) {
        setFormState(FormState.SUCCES)
      } else {
        switch (res.registerForNewsletter?.message) {
          case "Email address exists":
            setErrorMessage(
              "Het opgegevens e-mail adress is al reeds eerder toegevoegd aan de nieuwsbrief."
            )
            break
          case "Email: invalid address":
            setErrorMessage(
              "Het opgegevens e-mail adress is geen geldig e-mail adres."
            )
            break
          default:
            break
        }

        if (res.registerForNewsletter?.message === "Email address exists") {
          setErrorMessage(
            "Het opgegevens e-mail adress is al reeds eerder toegevoegd aan de nieuwsbrief."
          )
        }
        setFormState(FormState.FAILED)
      }
    }
  }

  switch (formState) {
    case FormState.LOADING:
      return (
        <Wrapper>
          <Spinner>
            <CircularProgress color="secondary" size="3rem" />
          </Spinner>
        </Wrapper>
      )
    case FormState.FAILED:
      return (
        <Wrapper>
          <Header color="primary" variant="h5" component="p" align="center">
            Helaas, er is iets mis gegaan.
          </Header>
          {errorMessage ? (
            <BaseText>{errorMessage}</BaseText>
          ) : (
            <>
              <BaseText>
                Het is helaas niet gelukt om je aan te melden voor de
                nieuwsbrief.
              </BaseText>
              <BaseText>
                Je kunt ons altijd mailen op{" "}
                <MuiLink href="mailto:info@thha.nl">info@thha.nl</MuiLink> of
                wij zijn telefonisch bereikbaar op{" "}
                <MuiLink href="tel:+31334655227">033-465 52 27</MuiLink>. Wij
                zijn van maandag t/m donderdag van 09:30 – 17.00 uur geopend.
              </BaseText>
              <BaseText>Onze excuses voor het ongemak.</BaseText>
            </>
          )}
          <BtnWrapper position="center">
            <Button onClick={reset} variant="contained" color="primary">
              Probeer opnieuw
            </Button>
          </BtnWrapper>
        </Wrapper>
      )
    case FormState.SUCCES:
      return (
        <Wrapper>
          <Header color="primary" variant="h5" component="p" align="center">
            Bedankt voor je aanmelding.
          </Header>
          <BaseText align="center">
            Het is gelukt om je aan te melden voor de nieuwsbrief.
          </BaseText>
          <BtnWrapper position="center">
            <Button component={Link} to="/" color="primary">
              naar de homepagina
            </Button>
          </BtnWrapper>
        </Wrapper>
      )
    case FormState.INIT:
    default:
      return (
        <Wrapper component="form" onSubmit={submit}>
          <Header color="secondary" variant="h5" component="p" align="center">
            Wil je de nieuwsbrief ontvangen?
          </Header>
          <NameWrapper>
            <BaseInput
              type="text"
              value={formData.firstName}
              onChange={update}
              id="firstName"
              name="Voornaam"
              label="Voornaam"
            />
            <BaseInput
              type="text"
              value={formData.lastName}
              onChange={update}
              id="lastName"
              name="Achternaam"
              label="Achternaam"
            />
          </NameWrapper>
          <BaseInput
            type="email"
            value={formData.email}
            onChange={update}
            fullWidth
            id="email"
            name="E-mail"
            label="E-mail"
          />
          <BtnWrapper position="flex-end">
            <Button type="submit" variant="contained" color="primary">
              aanmelden
            </Button>
          </BtnWrapper>
        </Wrapper>
      )
  }
}

export default Newsletter
