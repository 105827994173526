import MomentUtils from "@date-io/moment"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { Link } from "gatsby"
import { redirect } from "../../../helpers"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import useGqlClient from "../../../hooks/useGqlClient"
import {
  FormFieldNodeFragment,
  FormFragment,
} from "../../../__generated__/graphql-gatsby"
import { InputField } from "../../../__generated__/graphql-wp"
import Spacer from "../spacer"
import WpContent from "../wpContent"
import { FormWrapper, StyledForm } from "./styledForm"

type wpFieldNode = Maybe<FormFieldNodeFragment>

export type FormState = string[]

interface IProps {
  block: Maybe<FormFragment>
  initState?: FormState
  succesMessage?: React.ReactNode
  bigHeader?: boolean
  width?: number
  redirectUrl?: Maybe<string>
}

type ChangeSelect = (
  event: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | SelectEvent
  >,
  child?: React.ReactNode
) => void

type SelectEvent = {
  name?: string | undefined
  value: unknown
}

const InputTextField: React.FC<TextFieldProps> = props => (
  <TextField
    name={props.id}
    fullWidth
    color="secondary"
    variant="outlined"
    {...props}
  />
)

const Separator = styled(Divider)`
  margin-top: ${({ theme }) => theme.spacing(6)}px!important;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

const BtnWrapper = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing(4)}px`};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledFormLabel = styled(props => (
  <FormLabel component="legend" {...props} />
))`
  margin: ${({ theme }) => `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`};
`

const StyledFormControl = styled(props => (
  <FormControl component="fieldset" {...props} />
))`
  margin: ${({ theme }) => `${theme.spacing(2)}px 0`};
`

const Form: React.FC<IProps> = ({
  block,
  initState,
  succesMessage,
  bigHeader,
  width,
  redirectUrl,
}) => {
  const client = useGqlClient()
  const { title, showTitle, wpFields, formId } = { ...block }
  const [formData, setFormData] = useState<FormState>(initState || [])
  const [loading, setLoading] = useState(false)
  const init = "init"
  const [succes, setSucces] = useState<"init" | boolean>(init)

  useEffect(() => {
    wpFields?.nodes?.forEach(field => {
      if (field?.__typename === "WpListcountryField") {
        update(Number(field?.fieldId), "NL")
      }
    })
  }, [])

  const submit = async (e: React.FormEvent<HTMLInputElement>) => {
    if (typeof formData[0] === "undefined") {
      e.preventDefault()
      setLoading(true)

      const data: InputField[] = formData
        .map((value, index) => (value ? { id: index, value } : {}))
        .filter(el => el.hasOwnProperty("id"))

      try {
        const res = await client.SubmitForm({
          input: {
            clientMutationId: "submitForm",
            formId,
            data,
          },
        })

        if (redirectUrl && res.submitForm?.success) {
          redirect(redirectUrl)
        } else {
          setSucces(Boolean(res.submitForm?.success))
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setLoading(false)
        setSucces(false)
      }
    }
  }

  const update = (i: number, v: string) => {
    const newArray = [...formData]
    newArray[i] = v
    setFormData(newArray)
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const i = typeof index !== "undefined" ? index : Number(e.currentTarget.id)
    update(i, e.currentTarget.value)
  }

  const handleSelect: ChangeSelect = e => {
    if ("target" in e) {
      const i = Number(e.target.name)
      update(i, String(e.target.value))
    }
  }

  const handleDate = (date: MaterialUiPickersDate, index: number) => {
    const newDate = String(date?.toISOString())
    update(index, newDate)
  }

  const handleCheck = (
    event: React.ChangeEvent<{ name?: Maybe<string> }>,
    checked: boolean
  ) => {
    const i = Number(event.target.name)
    update(i, checked ? "checked" : "unchecked")
  }

  const reset = () => {
    setFormData(initState || [])
    setLoading(false)
    setSucces(init)
  }

  if (succes === init) {
    return (
      <FormWrapper width={width}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="nl">
          <StyledForm elevation={0} onSubmit={submit} isLoading={loading}>
            {showTitle ? (
              <>
                <Typography
                  variant={bigHeader ? "h1" : "h2"}
                  component="h2"
                  color="secondary"
                  align="center"
                >
                  {title}
                </Typography>
                <Spacer height={1} />
              </>
            ) : (
              <div />
            )}
            <InputTextField
              id="field"
              name="field"
              className="noshow"
              onChange={e => handleChange(e, 0)}
              disabled={loading}
            />

            {wpFields?.nodes?.map((field: wpFieldNode) => {
              const id = Number(field?.fieldId)
              const label = String(field?.fieldLabel)
              const type = String(field?.type)
              const required = Boolean(field?.required)

              switch (field?.__typename) {
                case "WpSubmitField":
                  return (
                    <BtnWrapper key={field.id}>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={loading}
                      >
                        {loading ? field.processingLabel : label}
                      </Button>
                    </BtnWrapper>
                  )
                case "WpTextareaField":
                  if (field.default === "verborgen") return null

                  return (
                    <InputTextField
                      key={field.id}
                      id={String(id)}
                      label={label}
                      type={type}
                      required={required}
                      multiline
                      rows={6}
                      value={formData[id] ? formData[id] : ""}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  )
                case "WpListcountryField":
                  return (
                    <Select
                      key={field.id}
                      id={String(id)}
                      name={String(id)}
                      value={formData[id] || "NL"}
                      onChange={handleSelect}
                      disabled={loading}
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      required={required}
                    >
                      <MenuItem value="NL">Nederland</MenuItem>
                      <MenuItem value="BE">België</MenuItem>
                      <MenuItem value="DE">Duitsland</MenuItem>
                    </Select>
                  )
                case "WpHrField":
                  return <Separator key={field.id} variant="middle" />
                case "WpHiddenField":
                  return null
                case "WpDateField":
                  if (!formData[id]) update(id, new Date().toDateString())

                  return (
                    <DatePicker
                      key={field.id}
                      id={String(id)}
                      name={String(id)}
                      value={formData[id]}
                      onChange={date => handleDate(date, id)}
                      disabled={loading}
                      fullWidth
                      color="primary"
                      inputVariant="outlined"
                      minDate={new Date()}
                      format={field.dateFormat || undefined}
                      label={label}
                      showTodayButton
                      cancelLabel="Annuleren"
                      todayLabel="Vandaag"
                      allowKeyboardControl
                      required={required}
                    />
                  )
                case "WpNumberField":
                case "WpTextboxField":
                  const placeholder = field.placeholder

                  return (
                    <InputTextField
                      key={field.id}
                      id={String(id)}
                      label={label}
                      type={type}
                      required={required}
                      value={formData[id] ? formData[id] : ""}
                      onChange={handleChange}
                      disabled={loading}
                      placeholder={placeholder ? placeholder : undefined}
                    />
                  )
                case "WpListselectField":
                  const defaultValue = field.options?.find(
                    option => option?.selected
                  )
                  if (!formData[id] && defaultValue)
                    update(id, String(defaultValue.value))

                  return (
                    <TextField
                      key={field.id}
                      fullWidth
                      select
                      label={label}
                      id={String(id)}
                      value={formData[id] ? formData[id] : ""}
                      name={String(id)}
                      onChange={handleSelect}
                      disabled={loading}
                      variant="outlined"
                      required={required}
                    >
                      {field.options?.map(option => (
                        <MenuItem
                          value={String(option?.value)}
                          key={option?.value}
                          style={{ width: "100%" }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                case "WpCheckboxField":
                  return (
                    <StyledFormControl key={field.id}>
                      {field.fieldLabel && (
                        <StyledFormLabel>
                          {field.fieldLabel}
                          {field.required && ` *`}
                        </StyledFormLabel>
                      )}
                      <FormControlLabel
                        label={
                          <Typography
                            variant="caption"
                            dangerouslySetInnerHTML={{
                              __html: String(field.description?.descText),
                            }}
                          />
                        }
                        disabled={loading}
                        onChange={handleCheck}
                        control={
                          <Checkbox
                            id={String(id)}
                            color="primary"
                            checked={
                              formData[id]
                                ? formData[id] === "checked"
                                : field.defaultValue === "checked"
                            }
                            name={String(id)}
                            required={required}
                          />
                        }
                      />
                    </StyledFormControl>
                  )
                case "WpHtmlField":
                  return (
                    <Typography
                      key={field.id}
                      component="div"
                      color="primary"
                      dangerouslySetInnerHTML={{
                        __html: String(field.default),
                      }}
                    />
                  )
                default:
                  return (
                    <InputTextField
                      key={field?.id}
                      id={String(id)}
                      label={label}
                      type={type}
                      required={required}
                      value={formData[id] ? formData[id] : ""}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  )
              }
            })}
          </StyledForm>
        </MuiPickersUtilsProvider>
      </FormWrapper>
    )
  }

  if (succes) {
    return (
      <Container maxWidth="sm">
        <WpContent>
          <section style={{ textAlign: "center" }}>
            {succesMessage ? (
              succesMessage
            ) : (
              <>
                <h1>Bedankt voor uw bericht.</h1>
                <p>
                  <strong>Uw bericht is succesvol bij ons aangekomen.</strong>
                </p>
                <p>Wij nemen zo spoedig mogelijk contact met u op.</p>
                <p>
                  Met vriendelijke groet,
                  <br /> het Toon Hermans huis Amersfoort
                </p>
              </>
            )}
            <Link to="/">Terug naar de home-pagina</Link>
          </section>
        </WpContent>
      </Container>
    )
  } else {
    return (
      <Container maxWidth="sm">
        <WpContent>
          <section style={{ textAlign: "center" }}>
            <h1>Helaas!</h1>
            <p>
              <strong>Er is iets mis gegaan.</strong>
            </p>
            <p>
              Probeert u het anders via de telefoon, of email. Ook kunt u het
              opnieuw proberen door op onderstaande knop te drukken.
            </p>
            <p>
              Met vriendelijke groet,
              <br /> het Toon Hermans huis Amersfoort
            </p>
            <Button color="primary" variant="outlined" onClick={reset}>
              Opnieuw
            </Button>
          </section>
        </WpContent>
      </Container>
    )
  }
}

export default Form
